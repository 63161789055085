import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        min-height: 100vh;
        position: relative;
        background-color: ${variables.dpc_backgroundWhite};
        

        .content {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;

            .layout-container {
                display: flex;
                justify-content: flex-end;
            }

            &.content-training-select {
                padding: 3em 0;
                
                .footer {
                    margin-top: 3em;
                }
            }

            .body {
                text-align: left;
                width: 100%;
            }
        }

        @media (max-width: ${variables.desktopXS}) {
            .content {}
        }
        @media (max-width: ${variables.tabletL}) {
            .layout-container {
                justify-content: center;
            }

            .content {}
        }

        

        @media (max-width: ${variables.mobileL}) {
            .content {}
        }

        @media (max-width: ${variables.mobileM}) {
            .content {}
        }
    `;

